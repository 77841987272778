import styled from "@emotion/styled";
import { mq } from "../components/layout";
import * as colors from "../utils/colors";

export const SectionWrapper = styled.div`
  &:nth-of-type(odd) {
    background: ${colors.LIGHT_BACKGROUND};
    // background: ${colors.GRAPIC_YELLOW};

    // background: linear-gradient(
    //   90deg,
    //   ${colors.GRAPIC_YELLOW} 0%,
    //   ${colors.GRAPIC_WHITE} 100%
    // );

    // ${mq[0]} {
    //   background: linear-gradient(
    //     90deg,
    //     ${colors.GRAPIC_YELLOW} 0%,
    //     ${colors.GRAPIC_WHITE} 95%
    //   );
    // }

    // ${mq[1]} {
    //   background: linear-gradient(
    //     90deg,
    //     ${colors.GRAPIC_YELLOW} 0%,
    //     ${colors.GRAPIC_WHITE} 90%
    //   );
    // }

    // ${mq[2]} {
    //   background: linear-gradient(
    //     90deg,
    //     ${colors.GRAPIC_YELLOW} 0%,
    //     ${colors.GRAPIC_WHITE} 80%
    //   );
    // }
  }
`;

export const Section = styled.div`
  max-width: 90rem;
  margin: auto;
  padding: 3rem 0.5rem;
  display: flex;
  flex-direction: column;
  ${mq[2]} {
    flex-direction: row;
    padding: 3rem 1rem 3rem 3rem;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 2.5rem;
`;
