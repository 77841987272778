import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";

import { Section, SectionWrapper, SectionHeading } from "./molecules";
import * as colors from "../utils/colors";

const Testimonial = ({ name, title, organization, location, quote, image }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      max-width: 36rem;
      min-height: 13rem;
      margin: 1rem;
      background: ${colors.GRAPIC_WHITE};
      padding: 1.5rem 2rem 1rem 2rem;
      border-radius: 1rem;
      justify-content: space-between;
    `}
  >
    <q
      css={css`
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 1.2rem;
      `}
    >
      {quote}
    </q>
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
      `}
    >
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        alt={name}
        css={css`
          width: 64px;
          max-width: 100%;
          margin-right: 1rem;
          margin-bottom: 0.5rem;
        `}
        imgStyle={{
          borderRadius: "1000px",
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-self: center;
        `}
      >
        <div>{name}</div>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              font-weight: 300;
            `}
          >{`${organization} · ${title} · ${location}`}</div>
        </div>
      </div>
    </div>
  </div>
);

const Testimonials = () => {
  const { vikasImage, saraImage, thomasImage } = useStaticQuery(
    graphql`
      {
        vikasImage: file(relativePath: { eq: "testimonials/vikas.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 64
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        saraImage: file(relativePath: { eq: "testimonials/sara.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 64
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        thomasImage: file(relativePath: { eq: "testimonials/thomas.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 64
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  return (
    <SectionWrapper>
      <Section>
        <div>
          <SectionHeading
            css={css`
              text-align: center;
            `}
          >
            {`What people are saying`}
          </SectionHeading>
          <div
            css={css`
              display: flex;
              flex-grow: 1;
              justify-content: center;
              flex-wrap: wrap;
            `}
          >
            <Testimonial
              name={`Vikas Reddy`}
              title={`Co-Founder/Board member`}
              organization={`Occipital`}
              location={`Boulder, Colorado`}
              quote={`Grapic is one of the best executed apps using computer vision and AR that I've seen. Simple, useful, and fun to use.`}
              image={vikasImage}
            />
            <Testimonial
              name={`Sara Malmström`}
              title={`Consultant`}
              organization={`Accenture`}
              location={`Stockholm`}
              quote={`I use Grapic to sketch out new slide ideas and concepts with my remote colleagues. It's the only thing that's as fast as drawing by hand, because that's what it is!`}
              image={saraImage}
            />
            <Testimonial
              name={`Thomas Walther`}
              title={`Founder`}
              organization={`Tape It`}
              location={`Distributed (Berlin & Stockholm)`}
              quote={`At Tape It we use Grapic to sketch our mobile app ideas. It’s so much faster to use pen & paper for brainstorming.`}
              image={thomasImage}
            />
          </div>
        </div>
      </Section>
    </SectionWrapper>
  );
};

export default Testimonials;
